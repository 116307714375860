import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { format, isAfter, isBefore } from "date-fns";

const sidebarSlice = createSlice({
	name: "sidebar",
	initialState: {
		isScheduleMeetingModalOpen: false,
		isLiveMeetingModalOpen: false,
		isLoading: false,
		Meetings: [],
	},
	reducers: {
		toggleScheduleMeetingModal: (state) => {
			state.isScheduleMeetingModalOpen = !state.isScheduleMeetingModalOpen;
		},
		closeScheduleMeetingModal: (state) => {
			state.isScheduleMeetingModalOpen = false; // Explicitly set to false for closing
		},
		toggleLiveMeetingModal: (state) => {
			state.isLiveMeetingModalOpen = !state.isLiveMeetingModalOpen;
		},
		closeLiveMeetingModal: (state) => {
			state.isLiveMeetingModalOpen = false;
		},
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setMeetings: (state, action) => {
			state.Meetings = action.payload;
		},
	},
});

// Export actions
export const {
	toggleScheduleMeetingModal,
	closeScheduleMeetingModal,
	toggleLiveMeetingModal,
	closeLiveMeetingModal,
	setLoading,
	setMeetings,
} = sidebarSlice.actions;

export const LiveMeetings = (userEmail) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		// const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/api/notetaker/allLiveEvents`, {userEmail,page,limit:8}
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/user/api/notetaker/allLiveEvents`,
			{
				userEmail,
			},
		);
		let filteredMeetings = [];
		const allMeetings = response.data?.alleventslist || [];
		// filteredMeetings = allMeetings.filter((meeting) => meeting.status === "completed");
		// console.log("All Meeting", filteredMeetings);

		dispatch(setMeetings(allMeetings));
	} catch (error) {
		console.error("Error fetching  meetings:", error);
	}
	dispatch(setLoading(false));
};
export const ScheduledMeeting = (userEmail) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/user/api/notetaker/allScheduleEvents`,
			{ userEmail },
		);
		let filteredMeetings = [];
		const allMeetings = response.data?.alleventslist || [];
		console.log("all Meeting", allMeetings);

		filteredMeetings = allMeetings.filter((meeting) => meeting.status === "completed");
		dispatch(setMeetings(filteredMeetings));
	} catch (error) {
		console.error("Error fetching  meetings:", error);
	}
	dispatch(setLoading(false));
};

export const IncompleteMeeting = (userEmail) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/user/api/notetaker/allScheduleEvents`,
			{ userEmail },
		);
		let filteredMeetings = [];
		const allMeetings = response.data?.alleventslist || [];
		const currentTime = new Date();
		console.log("all Meeting", allMeetings);

		filteredMeetings = allMeetings.filter(
			(meeting) => isBefore(new Date(meeting.start), currentTime) && meeting.status !== "completed",
		);
		dispatch(setMeetings(filteredMeetings));
	} catch (error) {
		console.error("Error fetching  meetings:", error);
	}
	dispatch(setLoading(false));
};

export const UpcomingMeeting = (userEmail) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/user/api/notetaker/allScheduleEvents`,
			{ userEmail },
		);
		let filteredMeetings = [];
		const allMeetings = response.data?.alleventslist || [];
		const currentTime = new Date();
		console.log("all Meeting", allMeetings);

		filteredMeetings = allMeetings.filter((meeting) => isAfter(new Date(meeting.start), currentTime));
		dispatch(setMeetings(filteredMeetings));
	} catch (error) {
		console.error("Error fetching  meetings:", error);
	}
	dispatch(setLoading(false));
};

// Export reducer
export default sidebarSlice.reducer;
