// // scheduleMeetingSlice.js
// import { createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';

// const scheduleMeetingSlice = createSlice({
//   name: 'scheduleMeeting',
//   initialState: {
//     formData: {
//       summary: '',
//       description: '',
//       startTime: null,
//       endTime: null,
//       attendees: [''], // Initialize with one empty attendee
//     },
//     isLoading: false,
//   },
//   reducers: {
//     updateFormData: (state, action) => {
//       state.formData = action.payload;
//     },
//     resetForm: (state) => {
//       state.formData = {
//         summary: '',
//         description: '',
//         startTime: null,
//         endTime: null,
//         attendees: [''],
//       };
//     },
//     setLoading: (state, action) => {
//       state.isLoading = action.payload;
//     },
//   },
// });

// export const { updateFormData, resetForm, setLoading } = scheduleMeetingSlice.actions;

// // Submit meeting action
// export const submitMeeting = (formData) => async (dispatch) => {
//   dispatch(setLoading(true));
//   const data = new FormData();
//   data.append('summary', formData.summary);
//   data.append('description', formData.description);
//   data.append('startTime', formData.startTime);
//   data.append('endTime', formData.endTime);
//   formData.attendees.forEach((attendee) => {
//     data.append('attendees', attendee); // Add each attendee
//   });

//   try {
//     await axios.post(`${process.env.REACT_APP_API_URL}/user/api/notetaker/schedule-event`, {data});
//     // Handle success response (optional)
//   } catch (error) {
//     console.error('Error scheduling meeting:', error);
//     // Handle error (optional)
//   }
//   dispatch(setLoading(false));
// };

// // Export reducer
// export default scheduleMeetingSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const scheduleMeetingSlice = createSlice({
	name: "scheduleMeeting",
	initialState: {
		formData: {
			source: "",
			summary: "",
			description: "",
			startTime: null,
			endTime: null,
			attendees: [""], // Initialize with one empty attendee
		},
		isLoading: false,
	},
	reducers: {
		updateFormData: (state, action) => {
			state.formData = action.payload;
		},
		resetForm: (state) => {
			state.formData = {
				source: "",
				summary: "",
				description: "",
				startTime: null,
				endTime: null,
				attendees: [""],
			};
		},
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
	},
});

export const { updateFormData, resetForm, setLoading } = scheduleMeetingSlice.actions;

// Submit meeting action
export const submitMeeting = (formData, userEmail) => async (dispatch) => {
	dispatch(setLoading(true));

	// Log formData to verify contents before sending
	console.log("FormData passed:", formData);

	// Create JSON data object
	const data = {
		source: formData.source,
		summary: formData.summary,
		description: formData.description,
		startTime: formData.startTime,
		endTime: formData.endTime,
		attendees: formData.attendees,
		usermail: userEmail,
	};

	console.log("Data being sent:", data);

	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/user/api/notetaker/schedule-event`,
			data,
		);
		console.log("Response:", response);
	} catch (error) {
		console.error("Error scheduling meeting:", error);
	}

	dispatch(setLoading(false));
};

// Export reducer
export default scheduleMeetingSlice.reducer;
