import React, { useState, useEffect } from "react";
import axios from "axios";

const IntegrationsOutlook = () => {
	const [status, setStatus] = useState("disconnected");
	const [calendarData, setCalendarData] = useState({}); // Initialize as an empty object

	// Retrieve user from sessionStorage
	const user = JSON.parse(sessionStorage.getItem("user"));

	useEffect(() => {
		const fetchUserStatus = async () => {
			if (user) {
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_API_URL}/auth/api/auth/status`,
						{
							email: user.email,
						},
					);

					setStatus(response.data.status);
					setCalendarData(response.data);
				} catch (error) {
					console.error("Error fetching status:", error);
				}
			} else {
				console.log("No user found in sessionStorage.");
			}
		};

		fetchUserStatus();
	}, []);

	const handleConnect = (service) => {
		window.location.href = `${process.env.REACT_APP_API_URL}/auth/api/auth/${service}`; // Redirect to start OAuth flow for the respective service
	};

	const handleDisconnect = () => {
		fetch("http://localhost:3000/auth/api/auth/disconnect")
			.then((response) => response.json())
			.then(() => {
				setStatus("disconnected");
			})
			.catch((error) => {
				console.error("Error during disconnect", error);
			});
	};

	return (
		<div>
			<h1>Microsoft Integration</h1>
			<p>Status: {status}</p>
			<ul>
				<li>
					Outlook: {calendarData.outlookMail ? calendarData.outlookMail : "No mail data available"}
				</li>
				<li>
					Google:{" "}
					{calendarData.calendarPreferences?.google ? (
						"Active"
					) : (
						<button onClick={() => handleConnect("google")}>Connect</button>
					)}
				</li>
				<li>
					Microsoft Teams:{" "}
					{calendarData.calendarPreferences?.msTeams ? (
						"Active"
					) : (
						<button onClick={() => handleConnect("microsoftTeams")}>Connect</button>
					)}
				</li>
				<li>
					Zoom:{" "}
					{calendarData.calendarPreferences?.zoom ? (
						"Active"
					) : (
						<button onClick={() => handleConnect("zoom")}>Connect</button>
					)}
				</li>
			</ul>
		</div>
	);
};

export default IntegrationsOutlook;
