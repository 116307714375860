import React, { useState, useEffect, useRef } from "react";
import VideoPlayer from "./VideoPlayer";
import AudioPlayer from "./AudioPlayer";
import MeetingNotes from "./MeetingNotes";
import MeetingHeading from "./MeetingHeading";

const MeetingTranscription = ({ meetTranscription }) => {
	console.log("MeetTranscription", meetTranscription);

	return (
		<div className="bg-gray-50">
			<MeetingHeading
				meetingId={meetTranscription?.meeting?.meetingId}
				userEmail={meetTranscription?.meeting?.userEmail}
				participant={meetTranscription?.meeting?.orderedSpeaker.length.toString()}
				source={meetTranscription?.meeting?.source}
				datetime={meetTranscription?.meeting?.meetingStartTime}
			/>
			<div className="grid grid-cols-1 md:grid-cols-5 gap-4">
				<div className="col-span-3 mx-3">
					{/* <h2 className="text-xl font-semibold mb-2">Transcript</h2> */}
					<MeetingNotes meetTranscription={meetTranscription} />
				</div>
				<div className="col-span-2 mr-3">
					{/* <h2 className="text-xl font-semibold mb-2">Video</h2> */}
					{meetTranscription?.videoAccessUrl && (
						<VideoPlayer videoUrl={meetTranscription?.videoAccessUrl} />
					)}
					{meetTranscription?.audioAccessUrl && (
						<AudioPlayer
							audioUrl={meetTranscription?.audioAccessUrl}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default MeetingTranscription;
