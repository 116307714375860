import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Loader from "../Loader";
import MeetingSummary from "./component/MeetingSummary";
import MeetingTranscription from "./component/MeetingTranscription";

const MeetingDetails = () => {
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [currentTab, setCurrentTab] = useState("meetingSummary");
	const [meetingDetails, setMeetingDetails] = useState(location.state?.meetingDetails || {});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const { meetingId, userEmail } = location.state;
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/user/api/notetaker/meetingdetails`,
					{ meetingId, userEmail },
				);
				setMeetingDetails(response.data);
			} catch (error) {
				console.log("Error:", error);
			} finally {
				setLoading(false);
			}
		};
		if (!location.state?.meetingDetails) {
			fetchData();
		} else {
			setLoading(false);
		}
	}, []);

	return (
		<div className="max-w-7xl mx-auto bg-white shadow-md rounded-md">
			{/* Fixed Tabs */}
			<div className="flex border-b border-gray-300 items-center sticky top-0 bg-white z-10">
				{/* Back Button */}
				<button
					className="flex items-center px-2 py-2 rounded-md"
					onClick={() => window.history.back()}
				>
					<AiOutlineArrowLeft size={20} color="#333333" />
				</button>

				{/* Tab Buttons */}
				<button
					onClick={() => setCurrentTab("meetingSummary")}
					className={`px-2 mx-4 py-3 text-sm font-medium ${
						currentTab === "meetingSummary"
							? "text-blue-600 border-b-2 border-blue-600"
							: "text-gray-500 hover:text-blue-600"
					}`}
				>
					Meeting Summary
				</button>
				<button
					onClick={() => setCurrentTab("meetingTranscription")}
					className={`px-2 mx-4 py-3 text-sm font-medium ${
						currentTab === "meetingTranscription"
							? "text-blue-600 border-b-2 border-blue-600"
							: "text-gray-500 hover:text-blue-600"
					}`}
				>
					Meeting Transcription
				</button>
			</div>

			{/* Scrollable Content */}
			{loading ? (
				<>
					<Loader />
				</>
			) : (
				<>
					<div className="py-3 px-1 overflow-y-auto">
						{currentTab === "meetingSummary" && (
							<MeetingSummary meetSummary={meetingDetails?.meeting} />
						)}
						{currentTab === "meetingTranscription" && (
							<MeetingTranscription meetTranscription={meetingDetails} />
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default MeetingDetails;
